import moment from 'moment-timezone';

import apiFn from 'common/services/api-service';

export const DATE_FORMAT = 'MMM D, YYYY';
export const TIME_FORMAT = 'h:mm a';
export const VIEW_ACTIVE = 'active';
export const VIEW_ARCHIVED = 'archived';

export const isValidDateRange = (startDate, endDate) => startDate.isSameOrBefore(endDate);

export const isValidTimeRange = (startTime, endTime) => {
  const startAsTimeOnly = moment(startTime.format(TIME_FORMAT), TIME_FORMAT);
  const endAsTimeOnly = moment(endTime.format(TIME_FORMAT), TIME_FORMAT);
  return startAsTimeOnly.isBefore(endAsTimeOnly);
};

export const splitDateAndTime = dateTime => {
  if (!dateTime) return [null, null];

  const dateTimeMoment = moment(dateTime);
  const date = moment(dateTimeMoment.format('YYYY-MM-DD'), 'YYYY-MM-DD');
  const time = moment(dateTimeMoment.format('HH:mm'), 'HH:mm');
  return [date, time];
};

const api = eventId =>
  apiFn(`/api/uapi/events/${eventId}/promo-codes`, {
    Accept: 'application/vnd.eventmobi+json; version=p.7',
  });

// eslint-disable-next-line import/prefer-default-export
export function create(eventId, params) {
  return api(eventId)
    .post('', params)
    .then(response => response.data?.data);
}

export const get = async (eventId, id) =>
  api(eventId, id)
    .get(id)
    .then(response => response.data.data);

export function list(eventId, payload = {}, query = '') {
  const params = new URLSearchParams(query);

  if (payload.offset > 0) {
    params.set('page', payload.offset / payload.limit);
  }

  if (payload.isArchived !== undefined) {
    params.set('isArchived', payload.isArchived);
  }

  return api(eventId)
    .get('', { params })
    .then(response => response.data);
}

export function update(eventId, id, params) {
  return api(eventId)
    .patch(id, params, {
      headers: {
        'x-nested-list-partial-updates': 'false',
      },
    })
    .then(response => response.data.data);
}

export function updateMultiple(eventId, params) {
  return api(eventId)
    .patch('', params)
    .then(response => response.data.data);
}

export async function getPromoCodesCounts(eventId) {
  const [activeCount, archivedCount] = (
    await Promise.all([
      list(eventId, { isArchived: false }, { limit: 0 }),
      list(eventId, { isArchived: true }, { limit: 0 }),
    ])
  ).map(res => res.meta.pagination.totalItemsCount);

  return [activeCount, archivedCount];
}
