function _arrayLikeToArray(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _arrayWithoutHoles(arr) {
    if (Array.isArray(arr)) return _arrayLikeToArray(arr);
}
function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _asyncToGenerator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
function _iterableToArray(iter) {
    if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}
function _nonIterableSpread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _toConsumableArray(arr) {
    return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread();
}
function _unsupportedIterableToArray(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _arrayLikeToArray(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}
var __generator = this && this.__generator || function(thisArg, body) {
    var f, y, t, g, _ = {
        label: 0,
        sent: function() {
            if (t[0] & 1) throw t[1];
            return t[1];
        },
        trys: [],
        ops: []
    };
    return g = {
        next: verb(0),
        "throw": verb(1),
        "return": verb(2)
    }, typeof Symbol === "function" && (g[Symbol.iterator] = function() {
        return this;
    }), g;
    function verb(n) {
        return function(v) {
            return step([
                n,
                v
            ]);
        };
    }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while(_)try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [
                op[0] & 2,
                t.value
            ];
            switch(op[0]){
                case 0:
                case 1:
                    t = op;
                    break;
                case 4:
                    _.label++;
                    return {
                        value: op[1],
                        done: false
                    };
                case 5:
                    _.label++;
                    y = op[1];
                    op = [
                        0
                    ];
                    continue;
                case 7:
                    op = _.ops.pop();
                    _.trys.pop();
                    continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                        _ = 0;
                        continue;
                    }
                    if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
                        _.label = op[1];
                        break;
                    }
                    if (op[0] === 6 && _.label < t[1]) {
                        _.label = t[1];
                        t = op;
                        break;
                    }
                    if (t && _.label < t[2]) {
                        _.label = t[2];
                        _.ops.push(op);
                        break;
                    }
                    if (t[2]) _.ops.pop();
                    _.trys.pop();
                    continue;
            }
            op = body.call(thisArg, _);
        } catch (e) {
            op = [
                6,
                e
            ];
            y = 0;
        } finally{
            f = t = 0;
        }
        if (op[0] & 5) throw op[1];
        return {
            value: op[0] ? op[1] : void 0,
            done: true
        };
    }
};
import { snakeCase } from "snake-case";
import apiFn from "../api-service";
var api = function(id) {
    var headers = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {
        Accept: "application/vnd.eventmobi+json; version=p.8",
        "x-nested-list-partial-updates": "false"
    };
    return apiFn("/api/uapi/events/".concat(id, "/people"), headers, !headers.Authorization);
};
export var list = function(eventId) {
    var payload = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, query = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : null, include = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : [
        "groups",
        "profileImage"
    ], token = arguments.length > 4 ? arguments[4] : void 0;
    var params = new URLSearchParams(query);
    var _params_get;
    var sort = ((_params_get = params.get("sort")) !== null && _params_get !== void 0 ? _params_get : "").split(",").filter(Boolean);
    if (include.length > 0) {
        params.set("include", include.map(function(v) {
            return snakeCase(v);
        }).join(","));
    }
    if (typeof payload.limit !== "undefined") {
        params.set("limit", String(payload.limit));
    }
    if (payload.offset > 0) {
        params.set("page", String(payload.offset / payload.limit));
    }
    if (payload.filterDeletionRequested) {
        params.set("deletionRequested", "true");
    }
    if (payload.sortByDeletionRequestedAt) {
        sort = [
            "deletionRequestedAt"
        ].concat(_toConsumableArray(sort));
    }
    if (sort.length > 0) {
        params.set("sort", sort.map(function(v) {
            return snakeCase(v);
        }).join(","));
    }
    var headers = token ? {
        Authorization: "Basic ".concat(token)
    } : {};
    return api(eventId, headers).get(null, {
        params: params
    }).then(function(response) {
        return response.data;
    });
};
export function listAll(eventId) {
    return _listAll.apply(this, arguments);
}
function _listAll() {
    _listAll = _asyncToGenerator(function(eventId) {
        var firstResult, count, requests, offset, results;
        return __generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        list(eventId)
                    ];
                case 1:
                    firstResult = _state.sent();
                    count = firstResult.meta.pagination.totalItemsCount;
                    requests = [];
                    offset = 1000;
                    while(offset < count){
                        requests.push(list(eventId, {
                            offset: offset,
                            limit: 1000
                        }));
                        offset += 1000;
                    }
                    return [
                        4,
                        Promise.all(requests)
                    ];
                case 2:
                    results = _state.sent();
                    results.splice(0, 0, firstResult);
                    return [
                        2,
                        results.map(function(result) {
                            return result.data;
                        }).flat()
                    ];
            }
        });
    });
    return _listAll.apply(this, arguments);
}
export function remove(eventId, id) {
    return api(eventId).delete(id).then(function(response) {
        return response === null || response === void 0 ? void 0 : response.data;
    });
}
export function update(eventId, id) {
    return _update.apply(this, arguments);
}
function _update() {
    _update = _asyncToGenerator(function(eventId, id) {
        var payload, updatedPerson;
        var _arguments = arguments;
        return __generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    payload = _arguments.length > 2 && _arguments[2] !== void 0 ? _arguments[2] : {};
                    return [
                        4,
                        api(eventId).patch(id, payload).then(function(response) {
                            var _response_data;
                            return response === null || response === void 0 ? void 0 : (_response_data = response.data) === null || _response_data === void 0 ? void 0 : _response_data.data;
                        })
                    ];
                case 1:
                    updatedPerson = _state.sent();
                    return [
                        2,
                        {
                            updatedPerson: updatedPerson
                        }
                    ];
            }
        });
    });
    return _update.apply(this, arguments);
}
export function create(eventId) {
    var payload = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    return api(eventId).post("", payload).then(function(response) {
        return response === null || response === void 0 ? void 0 : response.data;
    });
}
export function get(eventId, id) {
    var include = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : [];
    var params = new URLSearchParams();
    include.push("ticket", "groups", "custom_fields", "profile_image", "session_roles", "documents", "external_links");
    params.set("include", include.join(","));
    return api(eventId).get(id, {
        params: params
    }).then(function(response) {
        return response === null || response === void 0 ? void 0 : response.data;
    });
}
export function getSchedule(eventId, id) {
    return apiFn("/api/cms/v1/events/".concat(eventId, "/people/")).get("".concat(id, "/sessions")).then(function(response) {
        return response === null || response === void 0 ? void 0 : response.data;
    });
}
