import snakeCaseKeys from 'snakecase-keys';

import apiFn from 'common/services/api-service';

import {
  CONF_NESTED_FIELDS,
  CONF_DEFAULT_VALUES,
  CONF_CAN_EXPIRE,
  CONF_DEPENDENT_FIELDS,
} from './constants';

const api = eventId => apiFn(`/api/cms/v1/events/${eventId}/features`);

// transform takes out all configs to root level, adding `configType` to them
export const transform = data => {
  return {
    ...Object.fromEntries(
      Object.entries(data.configuration).map(([key, value]) => [
        key,
        {
          ...value,
          key,
          configType: 'configuration',
          defaultValue: CONF_DEFAULT_VALUES[key],
          subConfigKeys: CONF_NESTED_FIELDS[key],
          canExpire: CONF_CAN_EXPIRE.includes(key),
          expiry: data.expiry[key],
        },
      ])
    ),
    ...Object.fromEntries(
      Object.entries(data.features).map(([key, value]) => [
        key,
        {
          ...value,
          key,
          configType: 'feature',
          defaultValue: CONF_DEFAULT_VALUES[key],
          subConfigKeys: CONF_NESTED_FIELDS[key],
          canExpire: CONF_CAN_EXPIRE.includes(key),
          expiry: data.expiry[key],
        },
      ])
    ),
  };
};

// mergeWithDefaults adds `maxExpiryInDays` for all configs
export const mergeWithDefaults = (configs, defaults) => {
  return {
    ...Object.fromEntries(
      Object.entries(configs).map(([key, value]) => [
        key,
        {
          ...value,
          maxExpiryInDays: defaults[key]?.maxExpiryInDays,
        },
      ])
    ),
  };
};

// Recursively check if all dependencies of this config are enabled
export const areDependenciesEnabled = (baseKey, data) => {
  const dependencyKey = CONF_DEPENDENT_FIELDS[baseKey];
  if (!dependencyKey) {
    return true;
  }

  if (Array.isArray(dependencyKey)) {
    return dependencyKey.every(key => data[key]?.value && areDependenciesEnabled(key, data));
  }

  return data[dependencyKey]?.value && areDependenciesEnabled(dependencyKey, data);
};

export const prepare = (originalData, data) => {
  return Object.entries(data).reduce(
    (result, [key, config]) => {
      let { value } = config;

      if (originalData[key].type === 'boolean') {
        // A dependent config can only be enabled if any configs it depends on are
        // also enabled.
        value = config.value && areDependenciesEnabled(key, data);
      }

      if (originalData[key].configType === 'configuration') {
        // eslint-disable-next-line no-param-reassign
        result.configuration[key] = value;
      } else if (originalData[key].configType === 'feature') {
        // eslint-disable-next-line no-param-reassign
        result.features[key] = value;
      }

      // only set the expiry for the configs, that allows expiry and the key is enabled
      if (CONF_CAN_EXPIRE.includes(key) && value) {
        if (config.expiry?.utc) {
          // eslint-disable-next-line no-param-reassign
          result.expiry[key] = config.expiry.utc().format('YYYY-MM-DD');
        }
      }

      return result;
    },
    {
      configuration: {},
      features: {},
      expiry: {},
    }
  );
};

export function get(eventId) {
  return api(eventId)
    .get()
    .then(response => {
      // We are avoiding case conversion for any configuration API so that config keys are consistent with the backend.
      const data = snakeCaseKeys(response.data.data[0], { deep: true });

      // snakeCaseKeys is considering number to be a word boundary and converting it to _, so we need to manually convert it back
      const features = {
        ...data.features,
        is_a2a_messaging_enabled: data.features.is_a2_a_messaging_enabled,
      };

      return transform({ ...data, features });
    });
}

export const diff = (prev, next) =>
  Object.keys(next).reduce((list, key) => {
    if (next[key].value === prev[key].value) {
      return list;
    }

    return [...list, key];
  }, []);

export async function update(eventId, payload, { previous }) {
  const preparedPayload = prepare(previous, payload);
  const response = await api(eventId).post(null, preparedPayload);

  // we cannot use transform here because it doesn't return `type` for configuration and features
  return response.data.data;
}

export function updateCode(payload) {
  return apiFn('/api/cms/v1/change-event-shortcode')
    .post(null, payload)
    .then(response => response.data.data);
}

export const config = eventId => {
  const defaultConf = apiFn('/api/cms/v1/configuration_keys');
  const eventConf = apiFn(`/api/cms/v1/events/${eventId}/config`);

  const select = response => snakeCaseKeys(response.data.data, { deep: true });

  return Promise.all([defaultConf.get().then(select), eventConf.get().then(select)]);
};
