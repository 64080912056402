import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { omit } from 'lodash';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { t } from 'ttag';

import { list } from '@domains/People/fields-service';

import { ITEM_TYPE, getStaticFields } from './Form/FormTab/constants';
import {
  createSection,
  getFormItems,
  getPreviewLink,
  getSection,
  updateFormItems,
  updateSection,
} from './service';

// Preview link expires after 1 hour, but we refetch it a little
// more often than that to account for any potential latency.
const PREVIEW_LINK_REFETCH_INTERVAL = 57 * 60 * 1000;

export const previewLinkOptions = eventId => ({
  queryKey: ['events', eventId, 'registration', 'previewLink'],
  queryFn: () => getPreviewLink(eventId),
  refetchInterval: PREVIEW_LINK_REFETCH_INTERVAL,
});

export const usePreviewLink = () => {
  const { eventId } = useParams();
  return useQuery(previewLinkOptions(eventId));
};

const transformSection = ({ id: sectionId, formItems, ...rest }) => ({
  ...omit(rest, 'createdAt', 'updatedAt'),
  id: sectionId,
  type: 'section',
  required: t`N/A`,
  children: formItems
    .sort((itemA, itemB) => {
      return itemA.order - itemB.order;
    })
    .map(({ id: itemId, itemType, customField, ...restItems }) => ({
      ...omit(restItems, 'createdAt', 'updatedAt'),
      id: itemId,
      name:
        (itemType === ITEM_TYPE.CustomField && customField.name) ||
        (itemType === ITEM_TYPE.RichText && restItems.name) ||
        getStaticFields().find(({ fieldType }) => fieldType === itemType).name,
      itemType,
      customField,
      type: itemType === ITEM_TYPE.CustomField ? customField.type : 'text',
      parentId: sectionId,
    })),
});

const transformSections = sections => {
  const result = sections.map(transformSection).sort((sectionA, sectionB) => {
    return sectionA.order - sectionB.order;
  });

  return result;
};

export const registrationFormOptions = eventId => ({
  queryKey: ['events', eventId, 'registration', 'form', 'sections'],
  queryFn: () => getFormItems(eventId),
});

export const unlinkedFieldsOptions = eventId => ({
  queryKey: ['events', eventId, 'registration', 'form', 'unlinked-fields'],
  queryFn: () => list(eventId, { fieldsWithoutLinkType: 'registration' }),
});

export const useRegistrationForm = () => {
  const { eventId } = useParams();
  const { data, ...rest } = useQuery(registrationFormOptions(eventId));

  const memoizedData = useMemo(() => {
    if (!data) {
      return [];
    }

    return transformSections(data.data);
  }, [data]);

  return {
    ...rest,
    data: memoizedData,
  };
};

export const useRegistrationFormItems = () => {
  const { eventId } = useParams();

  const { data: formData, isLoading: isFormLoading } = useRegistrationForm();
  const { data: unlinkedFieldsData, isLoading: isUnlinkedFieldsLoading } = useQuery(
    unlinkedFieldsOptions(eventId)
  );

  const { sidebarFields, isFieldSearchEnabled } = useMemo(() => {
    const usedFields = new Set(
      formData
        .map(section => section.children)
        .flat()
        .map(item => item.name)
    );

    const unlinkedFields = unlinkedFieldsData ?? [];

    return {
      sidebarFields: getStaticFields()
        .filter(field => !usedFields.has(field.name))
        .concat(unlinkedFields),
      isFieldSearchEnabled: unlinkedFields.length > 0,
    };
  }, [formData, unlinkedFieldsData]);

  return {
    sections: formData,
    sidebarFields,
    isFieldSearchEnabled,
    isLoading: isFormLoading || isUnlinkedFieldsLoading,
  };
};

export const useRegistrationUpdateFormMutation = () => {
  const queryClient = useQueryClient();
  const { eventId } = useParams();
  return useMutation({
    mutationKey: ['events', eventId, 'registration', 'form', 'update'],
    mutationFn: ({ payload, config }) => updateFormItems(eventId, payload, config),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: registrationFormOptions(eventId).queryKey,
      });

      queryClient.invalidateQueries({
        queryKey: unlinkedFieldsOptions(eventId).queryKey,
      });
    },
  });
};

export const useRegistrationFormSection = (id, onError) => {
  const { eventId } = useParams();
  return useQuery({
    queryKey: ['events', eventId, 'registration', 'form', 'section', id],
    queryFn: () => getSection(eventId, id),
    onError,
    select: ({ data }) => data,
    enabled: !!id,
  });
};

export const useRegistrationCreateFormSectionMutation = () => {
  const queryClient = useQueryClient();
  const { eventId } = useParams();
  return useMutation({
    mutationKey: ['events', eventId, 'registration', 'form', 'section', 'create'],
    mutationFn: ({ payload }) => createSection(eventId, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: registrationFormOptions(eventId).queryKey,
      });
    },
  });
};

export const useRegistrationUpdateFormSectionMutation = () => {
  const queryClient = useQueryClient();
  const { eventId } = useParams();
  return useMutation({
    mutationKey: ['events', eventId, 'registration', 'form', 'section', 'update'],
    mutationFn: ({ sectionId, payload }) => updateSection(eventId, sectionId, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: registrationFormOptions(eventId).queryKey,
      });

      queryClient.invalidateQueries({
        queryKey: unlinkedFieldsOptions(eventId).queryKey,
      });
    },
  });
};
