import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { registrationFormOptions } from '@domains/Registration/queries';

import { create as createFn, list as listFn, updateBatch as updateBatchFn } from './service';

export const formConditionsOptions = eventId => ({
  queryKey: ['event', eventId, 'registration', 'formConditions'],
  queryFn: () => listFn(eventId, { sort: 'order' }).then(response => response.data),
});

export const useFormConditionsQuery = (queryOptions = {}) => {
  const { eventId } = useParams();

  return useQuery({
    ...formConditionsOptions(eventId),
    ...queryOptions,
  });
};

export const useCreateFormConditionMutation = () => {
  const { eventId } = useParams();
  const queryClient = useQueryClient();
  const conditionsKey = formConditionsOptions(eventId).queryKey;

  return useMutation({
    mutationFn: ({ payload }) => {
      const existingData = queryClient.getQueryData(conditionsKey);
      const firstOrder = existingData.reduce((acc, condition) => Math.min(acc, condition.order), 0);
      return createFn(eventId, { ...payload, order: firstOrder - 1 });
    },
    // Awaiting this for new conditions so we don't get a flash of the empty
    // state while isEmpty && !isLoading prior to the refetch.
    onSuccess: async () =>
      Promise.all([
        queryClient.invalidateQueries(conditionsKey),
        queryClient.invalidateQueries(registrationFormOptions(eventId)),
      ]),
  });
};

export const useUpdateFormConditionMutation = () => {
  const { eventId } = useParams();
  const queryClient = useQueryClient();
  const conditionsKey = formConditionsOptions(eventId).queryKey;

  return useMutation({
    mutationFn: ({ payload }) => {
      // we need to mark triggers and actions as deleted if they were previously in the condition
      // but are not in the payload

      const existingData = queryClient.getQueryData(conditionsKey);
      const existingCondition = existingData.find(condition => condition.id === payload.id);

      const existingTriggerIds = existingCondition.triggers
        .map(trigger => trigger.id)
        .filter(Boolean);
      const existingActionIds = existingCondition.actions.map(action => action.id).filter(Boolean);

      const deletedTriggerIds = existingTriggerIds.filter(
        id => !payload.triggers.find(t => t.id === id)
      );
      const deletedActionIds = existingActionIds.filter(
        id => !payload.actions.find(a => a.id === id)
      );

      const updatedTriggers = [
        ...payload.triggers,
        ...deletedTriggerIds.map(id => ({ id, deleted: true })),
      ];
      const updatedActions = [
        ...payload.actions,
        ...deletedActionIds.map(id => ({ id, deleted: true })),
      ];

      return updateBatchFn(eventId, [
        { ...payload, triggers: updatedTriggers, actions: updatedActions },
      ]);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(conditionsKey);
      queryClient.invalidateQueries(registrationFormOptions(eventId));
    },
  });
};

export const useReorderFormConditionMutation = () => {
  const { eventId } = useParams();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ payload }) => {
      const updated = payload.map((condition, index) => ({
        id: condition.id,
        order: index,
      }));

      return updateBatchFn(eventId, updated);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(formConditionsOptions(eventId).queryKey);
    },
  });
};

export const useDeleteFormConditionMutation = () => {
  const { eventId } = useParams();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id }) => updateBatchFn(eventId, [{ id, deleted: true }]),
    onSuccess: () => {
      queryClient.invalidateQueries(formConditionsOptions(eventId).queryKey);
      queryClient.invalidateQueries(registrationFormOptions(eventId));
    },
  });
};
