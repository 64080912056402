import { InboxOutlined } from '@ant-design/icons';
import { Upload, message, Spin } from 'antd';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { t } from 'ttag';

import { formatBytes } from '@services/file-service';

import {
  notify as notifyFn,
  create as createFn,
  upload as uploadFn,
  remove as removeFn,
  getDownloadUrl as getDownloadUrlFn,
  ensureMimeType,
  STATUS_FINALIZED,
  ALLOWED_MIME_TYPES,
} from './service';

const SUPPORTED_TYPES = [
  '.doc',
  '.docx',
  '.ppt',
  '.pptx',
  '.xls',
  '.xlsx',
  '.pdf',
  '.jpeg',
  '.jpg',
  '.png',
  '.bmp',
  '.webp',
  '.keystore',
  '.json',
  '.mobileprovision',
  '.cer',
  '.p12',
  '.pem',
];

const { Dragger } = Upload;

const MAXIMUM_ALLOWED_FILE_SIZE = 1024 * 1024 * 24;

const STATUS_DONE = 'done';
const STATUS_ERROR = 'error';

export default function FileUpload({
  resourceId,
  resourceType,
  supportedTypes = SUPPORTED_TYPES,
  information,
  // Component is designed in a way to handle multiple/single file(s) upload, so if allowedFileCount is 1 it expects a single object e.g. {id,name,url}
  // else it will expect array of object [{id,name,url}]
  // similary it will return single or array of object onChange event.
  value,
  allowedFileCount = 1,
  onChange = () => {},
  FileRenderer = null,
}) {
  let initialValue;
  if (allowedFileCount === 1) {
    initialValue = value ? [value] : [];
  } else {
    initialValue = value ?? [];
  }
  const [values, setValues] = useState(initialValue);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function effect() {
      try {
        setLoading(true);
        const valuesWithUrl = await Promise.all(
          values.map(x =>
            getDownloadUrlFn(resourceType, resourceId, x.id).then(url => ({ ...x, uid: x.id, url }))
          )
        );
        setValues(valuesWithUrl);
      } finally {
        setLoading(false);
      }
    }
    effect();
    // TODO Remove the eslint-disable and fix the problem
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, resourceId, resourceType]);

  const [fileCount, setFileCount] = useState(value?.length ?? 0);

  const supportedTypesForTranslation = supportedTypes.map(ext => `*${ext}`).join(', ');
  const maxFileSize = formatBytes(MAXIMUM_ALLOWED_FILE_SIZE);

  const handleChange = info => {
    const { file } = info;

    if (file.status === STATUS_DONE) {
      message.success(t`${info.file.name} uploaded successfully.`);
    } else if (file.status === STATUS_ERROR) {
      message.error(t`${info.file.name} upload failed.`);
    }
  };

  const onRequest = async request => {
    try {
      setLoading(true);
      const { file } = request;
      const onProgress = progress =>
        request.onProgress({ percent: (progress.loaded / progress.total) * 100 });

      const { id, name, upload } = await createFn(resourceType, resourceId, file);

      try {
        request.onSuccess(await uploadFn(upload.url, file, onProgress));
      } catch (err) {
        request.onError(err);
      }

      await notifyFn(resourceType, resourceId, id, STATUS_FINALIZED);
      const newValue = {
        id,
        name,
        url: upload?.url,
      };
      if (allowedFileCount === 1) {
        setValues([newValue]);
        onChange(newValue);
      } else {
        const newValues = [...values, newValue];
        setValues(newValues);
        onChange(newValues);
      }
    } finally {
      setLoading(false);
    }
  };

  const beforeUpload = (file, list) => {
    if (file.size > MAXIMUM_ALLOWED_FILE_SIZE) {
      message.error(t`${file.name} exceeds maximum size limit (${maxFileSize})`);
      // eslint-disable-next-line no-param-reassign
      file.status = 'error';
      return false;
    }

    ensureMimeType(file);

    // validation for unsupported file types
    if (!ALLOWED_MIME_TYPES.includes(file.derivedType || file.type)) {
      message.error(t`${file.name} is not a supported file type`);

      list.splice(list.indexOf(file), 1);
      return false;
    }

    setFileCount(list?.length + fileCount);
    return true;
  };

  const onRemove = async file => {
    try {
      setLoading(true);
      // The API will return an error if the file has not been associated with a record yet
      // e.g. the user uploaded the file and right after deleted before clicking in save
      // I dont want to thrown any error to the user because we have a job to clean those files after
      await removeFn(resourceType, resourceId, file.id);
      if (allowedFileCount === 1) {
        setValues([]);
        onChange(undefined);
      } else {
        const index = values.findIndex(x => x.id === file.id);
        const newValues = [...values.splice(index, 1)];
        setValues(newValues);
        onChange(newValues);
      }
    } catch {
      // intentionally eating up the exception
    } finally {
      setLoading(false);
    }
  };

  if (values.length && FileRenderer) {
    return (
      <FileRenderer
        files={values}
        onRemove={onRemove}
        onDownload={id => getDownloadUrlFn(resourceType, resourceId, id)}
      />
    );
  }

  return (
    <Dragger
      showUploadList
      multiple={allowedFileCount > 1}
      customRequest={onRequest}
      beforeUpload={beforeUpload}
      accept={supportedTypes.join(',')}
      onChange={handleChange}
      onRemove={onRemove}
      fileList={values}
      disabled={loading}
    >
      {loading ? (
        <Spin />
      ) : (
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
      )}
      <p className="ant-upload-text">{t`Click or drag file to this area to upload`}</p>
      <p className="ant-upload-hint">{t`Supported file types: ${supportedTypesForTranslation}`}</p>
      <p className="ant-upload-hint">
        {t`The maximum file size per file is ${maxFileSize}.`} {information}
      </p>
    </Dragger>
  );
}

FileUpload.propTypes = {
  resourceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  resourceType: PropTypes.oneOf(['events', 'organizations']).isRequired,
  supportedTypes: PropTypes.arrayOf(PropTypes.oneOf(SUPPORTED_TYPES)),
  onChange: PropTypes.func,
  information: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        url: PropTypes.string,
      })
    ),
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      url: PropTypes.string,
    }),
  ]),
  allowedFileCount: PropTypes.number,
  FileRenderer: PropTypes.func,
};
