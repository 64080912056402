import { InfoCircleOutlined } from '@ant-design/icons';
import { Table as AntTable, Button, Space, Tooltip } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { msgid, ngettext, t } from 'ttag';

import useTable from 'common/hooks/useTable';
import { isEnabled as isFlagEnabled } from 'common/state/flags';
import { getId } from 'common/state/organization';

import SearchFilter, { SearchFilterIcon } from '@components/SearchFilter';
import { EXPORT_USER_CREDITS_USAGE } from '@domains/Export/constants';
import Export from '@domains/Export/Polling';
import {
  events as listDataUsageFn,
  LIVESTREAM_BROADCAST_HOURS,
  LIVESTREAM_PLAYBACK_HOURS,
  VIDEO_STORAGE_HOURS,
  VIDEO_PLAYBACK_HOURS,
  PEOPLE_CREDITS,
} from '@services/data-usage-service';
import hashFn from '@services/hash-service';

import { events as listEventUsageFn } from '../contracts-service';

const { Column } = AntTable;

const USAGE_CUTOFF = 0;

export const formatUsage = (type, value) => {
  if (type === PEOPLE_CREDITS) {
    return ngettext(msgid`${value} user`, `${value} users`, value);
  }

  const hours = Math.floor(value / 3600);
  const minutes = Math.floor((value % 3600) / 60);
  const seconds = Math.floor((value % 3600) % 60);

  const labelHours = ngettext(msgid`${hours} hour`, `${hours} hours`, hours);
  const labelMinutes = ngettext(msgid`${minutes} minute`, `${minutes} minutes`, minutes);
  const labelSeconds = ngettext(msgid`${seconds} second`, `${seconds} seconds`, seconds);

  return hours > 0
    ? `${labelHours} ${labelMinutes} ${labelSeconds}`
    : `${labelMinutes} ${labelSeconds}`;
};

const onFilter = (value, record, fieldName) =>
  record[fieldName]?.toString().toLowerCase().includes(value?.toLowerCase());

export const Table = ({ type, data, loading, pagination, onClick, onChange, actionName }) => {
  const isUserCreditLicensingEnabled = useSelector(state =>
    isFlagEnabled(state, 'user_credit_licensing_v2')
  );

  let dataSource = data;

  // Remove isUserCreditLicensingEnabled while removing the user_credit_licensing_v2 FF
  if (isUserCreditLicensingEnabled && type === PEOPLE_CREDITS) {
    dataSource = data[0]?.creditsUsageByEvents;
  }

  return (
    <AntTable
      tableLayout="auto"
      rowKey={record => hashFn(record)}
      dataSource={dataSource}
      loading={loading}
      pagination={pagination}
      onChange={onChange}
    >
      {
        // Remove isUserCreditLicensingEnabled while removing the user_credit_licensing_v2 FF
        isUserCreditLicensingEnabled && type === PEOPLE_CREDITS ? (
          <>
            <Column
              title={t`Event`}
              dataIndex="name"
              sorter={(a, b) => a.name.localeCompare(b.name)}
              filterDropdown={SearchFilter}
              filterIcon={SearchFilterIcon}
              onFilter={(value, record) => onFilter(value, record, 'name')}
            />
            <Column
              title={t`Start Date`}
              dataIndex="startDate"
              sorter={(a, b) => moment(a.startDate) - moment(b.startDate)}
              render={date => moment(date).format('ll')}
            />
            <Column
              title={t`End Date`}
              dataIndex="endDate"
              sorter={(a, b) => moment(a.endDate) - moment(b.endDate)}
              render={date => moment(date).format('ll')}
            />
            <>
              <Column
                title={t`Number of People`}
                dataIndex="numberOfPeople"
                sorter={(a, b) => a.numberOfPeople - b.numberOfPeople}
                align="right"
              />
              <Column
                title={t`Credit Usage`}
                dataIndex="creditsUsed"
                defaultSortOrder="descend"
                sorter={(a, b) => a.creditsUsed - b.creditsUsed}
                align="right"
                render={value => ngettext(msgid`${value} Credit`, `${value} Credits`, value)}
              />
            </>
          </>
        ) : (
          <>
            <Column title={t`Event`} dataIndex="name" sorter />
            <Column title={t`Start Date`} dataIndex="startDate" sorter />
            <Column title={t`End Date`} dataIndex="endDate" sorter />
            <Column
              title={t`Usage`}
              dataIndex="limitUsed"
              sorter
              defaultSortOrder="descend"
              render={value => formatUsage(type, value)}
            />
          </>
        )
      }
      <Column
        title={t`Action`}
        render={record => (
          <Button type="link" onClick={() => onClick(record.id)}>
            {actionName}
          </Button>
        )}
      />
    </AntTable>
  );
};

Table.propTypes = {
  type: PropTypes.oneOf([
    LIVESTREAM_BROADCAST_HOURS,
    LIVESTREAM_PLAYBACK_HOURS,
    VIDEO_STORAGE_HOURS,
    VIDEO_PLAYBACK_HOURS,
    PEOPLE_CREDITS,
  ]).isRequired,
  data: PropTypes.arrayOf(Object).isRequired,
  loading: PropTypes.bool,
  // Same as https://ant.design/components/pagination/#API
  // Note: We're only plucking the props that we're using.
  pagination: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      current: PropTypes.number.isRequired,
      pageSize: PropTypes.number.isRequired,
      total: PropTypes.number.isRequired,
      onChange: PropTypes.func,
      showTotal: PropTypes.func,
    }),
  ]),
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  actionName: PropTypes.string.isRequired,
};

const UsageLimitDetails = ({ type, entity }) => {
  const history = useHistory();
  const organizationId = useSelector(getId);

  const isUserCreditLicensingEnabled = useSelector(state =>
    isFlagEnabled(state, 'user_credit_licensing_v2')
  );

  const [showExportModal, setShowExportModal] = useState(false);

  const listFunc = useCallback(
    (...args) => {
      // Remove isUserCreditLicensingEnabled while removing the user_credit_licensing_v2 FF
      if (isUserCreditLicensingEnabled && type === PEOPLE_CREDITS) {
        return listEventUsageFn(organizationId, entity?.id, ...args);
      }
      return listDataUsageFn(organizationId, type, USAGE_CUTOFF, ...args);
    },
    [organizationId, type, entity?.id, isUserCreditLicensingEnabled]
  );

  const { dataSource, loading, pagination, onChange } = useTable(listFunc, {
    sort: { field: 'limitUsed', order: 'descend' },
  });

  const isLiveStream = [LIVESTREAM_BROADCAST_HOURS, LIVESTREAM_PLAYBACK_HOURS].includes(type);

  const getActionLabel = () => {
    if (type === PEOPLE_CREDITS) {
      return t`Go to People Library`;
    }
    if (isLiveStream) {
      return t`View Analytics`;
    }
    return t`Video Library`;
  };

  const onClick = eventId => {
    let path;
    if (type === PEOPLE_CREDITS) {
      path = 'people';
    } else if (isLiveStream) {
      path = 'analytics?tab=livestream';
    } else {
      path = 'videos';
    }

    history.push(`/organization/${organizationId}/event/${eventId}/${path}`);
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      {type === PEOPLE_CREDITS && (
        <Space style={{ width: '100%', justifyContent: 'end' }}>
          <Button onClick={() => setShowExportModal(true)}>{t`Export`}</Button>
          {/* Remove isUserCreditLicensingEnabled while removing the user_credit_licensing_v2 FF */}
          {isUserCreditLicensingEnabled && (
            <Tooltip
              placement="topLeft"
              title={t`Export a report of credit usage for each attendee, per event, including the contract period.`}
            >
              <InfoCircleOutlined css="cursor: pointer" />
            </Tooltip>
          )}
          {showExportModal && (
            <Export
              organizationId={organizationId}
              type={EXPORT_USER_CREDITS_USAGE}
              payload={{ contract_id: entity.id }}
              open
              onClose={() => setShowExportModal(false)}
            />
          )}
        </Space>
      )}
      <Table
        type={type}
        data={dataSource}
        loading={loading}
        // Remove isUserCreditLicensingEnabled while removing the user_credit_licensing_v2 FF
        pagination={isUserCreditLicensingEnabled && type === PEOPLE_CREDITS ? false : pagination}
        onChange={onChange}
        onClick={onClick}
        actionName={getActionLabel()}
      />
    </Space>
  );
};

UsageLimitDetails.propTypes = {
  type: PropTypes.oneOf([
    LIVESTREAM_BROADCAST_HOURS,
    LIVESTREAM_PLAYBACK_HOURS,
    VIDEO_STORAGE_HOURS,
    VIDEO_PLAYBACK_HOURS,
    PEOPLE_CREDITS,
  ]).isRequired,
  entity: PropTypes.shape({}),
};

export default UsageLimitDetails;
