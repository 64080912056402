import { toQuery } from 'common/hooks/query-service';
import apiFn from 'common/services/api-service';
import { list as getPeopleFn } from 'common/services/people/api';

import { list as listPeopleGroupsFn } from '@domains/People/groups-service';
import { list as listSessionRolesFn } from '@domains/Sessions/roles-service';

import { prepareDataForBleed } from './designer-service';

const api = (eventId, token) => {
  let header = { Accept: 'application/vnd.eventmobi+json; version=p.5' };
  const withCredentials = !token;

  if (token) {
    header = { ...header, Authorization: `Basic ${token}` };
  }

  return apiFn(`/api/uapi/events/${eventId}/badges`, header, withCredentials);
};

export function list(eventId, badgeType, include = []) {
  const params = {};

  if (badgeType) {
    params.badgeType = badgeType;
  }

  if (include.length > 0) {
    params.include = include.join(',');
  }

  return api(eventId)
    .get(null, { params })
    .then(response => response.data.data);
}

export function get(eventId, id, token) {
  return api(eventId, token)
    .get(id)
    .then(response => prepareDataForBleed(response.data.data));
}

export function create(eventId, payload) {
  return api(eventId)
    .post(null, payload)
    .then(response => response.data.data);
}

export function applyTemplate(eventId, id, templateId) {
  return api(eventId)
    .post(`${id}/apply-template`, { badgeTemplateId: templateId })
    .then(response => response.data.data);
}

export function update(eventId, badgeId, payload) {
  return api(eventId)
    .patch(badgeId, payload)
    .then(response => prepareDataForBleed(response.data.data));
}

export function duplicate(eventId, badgeId, payload) {
  return api(eventId)
    .post(`${badgeId}/duplicate`, payload)
    .then(response => response.data.data);
}

export function remove(eventId, id) {
  return api(eventId)
    .delete(id)
    .then(response => response.data.data);
}

export const getLabels = async (eventId, peopleEntityIds = null) => {
  const queries = [
    listPeopleGroupsFn(eventId).then(({ data }) => data),
    listSessionRolesFn(eventId).then(({ data }) => data),
  ];

  if (peopleEntityIds && peopleEntityIds.length) {
    const query = toQuery({
      filter: {
        id: peopleEntityIds,
      },
      limit: 1000,
      offset: 0,
    });

    queries.push(getPeopleFn(eventId, {}, query, []).then(({ data }) => data));
  }

  const [peopleGroups, sessionRoles, people] = await Promise.all(queries);

  return {
    peopleGroups,
    sessionRoles,
    people,
  };
};
