import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Checkbox,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { t } from 'ttag';

import { isEnabled } from 'common/state/flags';

import Switch from '@components/Switch';

import { CONF_OPTIONS, CONF_DEPENDENT_FIELDS } from './constants';
import { areDependenciesEnabled } from './service';

const DATE_FORMAT = 'MMM D, YYYY';

const Validator = () => ({
  lead_capture_license_limit: [
    {
      validator: async (_, value) => {
        if (value > 9999 || value < 1) {
          throw new Error('error');
        }
      },
      message: t`The value must be ranged from 1 to 9999.`,
    },
  ],
});

// ToggleInput shows an input component when the switch is ON.
// Otherwise it sets the value to null
const ToggleInput = ({
  label,
  value,
  defaultVal,
  onChange = () => {},
  component,
  ...componentProps
}) => {
  const [initialValue] = useState(defaultVal ?? value);
  const [show, setShow] = useState(!!value);
  const Component = component;

  const handleSwitch = v => {
    setShow(v);
    onChange(v ? initialValue : null);
  };

  const handleComponentChange = v => {
    onChange(v);
  };

  return (
    <Space direction="vertical" size="small">
      <Switch checked={show} onChange={handleSwitch} label={label} />

      {show && <Component {...componentProps} value={value} onChange={handleComponentChange} />}
    </Space>
  );
};

ToggleInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  defaultVal: PropTypes.any,
  onChange: PropTypes.func,
  component: PropTypes.elementType,
};

const FieldPropTypes = {
  configKey: PropTypes.string.isRequired,
  config: PropTypes.objectOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['boolean', 'integer', 'text', 'radio']).isRequired,
      name: PropTypes.string.isRequired,
      subConfigKeys: PropTypes.arrayOf(PropTypes.string),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
      canExpire: PropTypes.bool,
      maxExpiryInDays: PropTypes.number,
      expiry: PropTypes.object,
    })
  ).isRequired,
};

const BooleanField = ({ configKey, config }) => {
  const { name, value: initialValue, canExpire, maxExpiryInDays, subConfigKeys } = config[
    configKey
  ];
  const isOnsiteBadgePrintingEnabled = useSelector(state =>
    isEnabled(state, 'onsite_badge_printing')
  );
  const [value, setValue] = useState(initialValue);
  const subConfigs = subConfigKeys?.map(k => config[k]).filter(Boolean) ?? [];
  const invalidSubConfigs = subConfigKeys?.filter(k => !config[k]) ?? [];
  const invalidSubConfigKeysStr = invalidSubConfigs.join(', ');

  const disabledDate = current => {
    const minDate = moment().utc().startOf('day');
    const tooEarly = current.utc().isBefore(minDate, 'day');

    if (maxExpiryInDays === null) return tooEarly;

    const maxDate = moment().utc().add(maxExpiryInDays, 'days').endOf('day');
    const tooLate = current.utc().isAfter(maxDate, 'day');
    return tooEarly || tooLate;
  };

  return (
    <>
      <Form.Item name={[configKey, 'value']} valuePropName="checked">
        <Switch
          onChange={v => setValue(v)}
          label={
            <Tooltip placement="right" title={configKey}>
              {name}
            </Tooltip>
          }
        />
      </Form.Item>

      {/*
        This should never happen in real-life, but it's a life saver when you forget to add a subconfig
        to the mock config object in the test
      */}
      {invalidSubConfigs.length > 0 && (
        <Typography.Paragraph type="danger">
          {t`Unknown subconfig keys: ${invalidSubConfigKeysStr}`}
        </Typography.Paragraph>
      )}
      {/* TODO: Remove the filter when remove onsite_badge_printing FF */}
      {subConfigs
        .filter(
          subConfig =>
            subConfig.key !== 'badge_printing_onsite_labels_enabled' || isOnsiteBadgePrintingEnabled
        )
        .map(subConfig => (
          <Form.Item key={subConfig.key} noStyle shouldUpdate>
            {({ getFieldsValue, getFieldValue }) => {
              const itemProps = {
                hidden: !getFieldValue(configKey).value,
                name: [subConfig.key, 'value'],
                style: { marginLeft: 36 },
                rules: value ? Validator()[subConfig.key] : null,
              };

              let input;
              let disabled = false;

              // A dependent config is only toggleable if some other config is enabled
              const dependentKey = CONF_DEPENDENT_FIELDS[subConfig.key];
              if (dependentKey) {
                itemProps.dependencies = [dependentKey, 'value'];
                disabled = !areDependenciesEnabled(subConfig.key, getFieldsValue());
              }

              if (subConfig.type === 'boolean') {
                input = subConfig.type === 'boolean' && (
                  <Checkbox disabled={disabled}>
                    <Tooltip placement="right" title={subConfig.key}>
                      {subConfig.name}
                    </Tooltip>
                  </Checkbox>
                );

                itemProps.valuePropName = 'checked';
              } else {
                input =
                  (subConfig.type === 'integer' && (
                    <InputNumber disabled={disabled} size="default" />
                  )) ||
                  (subConfig.type === 'text' && <Input disabled={disabled} size="default" />);

                itemProps.valuePropName = 'value';
                itemProps.label = (
                  <Tooltip placement="right" title={subConfig.key}>
                    {subConfig.name}
                  </Tooltip>
                );
              }

              return <Form.Item {...itemProps}>{input}</Form.Item>;
            }}
          </Form.Item>
        ))}

      {canExpire && (
        <Form.Item
          name={[configKey, 'expiry']}
          hidden={!value}
          label={t`Expiry Date`}
          style={{ marginLeft: 36, marginTop: -10 }}
          rules={[{ required: value, message: t`Expiry Date is required` }]}
        >
          <DatePicker
            disabledDate={disabledDate}
            format={DATE_FORMAT}
            showToday={false}
            size="default"
          />
        </Form.Item>
      )}
    </>
  );
};

BooleanField.propTypes = FieldPropTypes;

const Field = ({ configKey, config }) => {
  const { type, name, defaultValue } = config[configKey];

  /**
   * This configuration key have custom validation and business rule.
   * I am adding this condition for now since we are planning to revamp configuration for managemobi migration
   */

  if (configKey === 'ea_max_concurrent_logins') {
    return (
      <Space direction="vertical" size="small">
        <Typography.Text>
          <Tooltip placement="right" title={configKey}>
            {name}
          </Tooltip>
        </Typography.Text>
        <Form.Item
          name={[configKey, 'value']}
          rules={[
            {
              validator: async (rule, value) => {
                if (value > 5 || value < 1) {
                  throw new Error('error');
                }
              },
              message: t`The value must be ranged from 1 to 5.`,
            },
          ]}
        >
          <InputNumber size="default" />
        </Form.Item>
      </Space>
    );
  }

  if (type === 'boolean') {
    return <BooleanField config={config} configKey={configKey} />;
  }

  if (type === 'radio') {
    return (
      <Form.Item
        label={
          <Tooltip placement="right" title={configKey}>
            <Switch disabled checked label={name} />
          </Tooltip>
        }
        name={[configKey, 'value']}
      >
        <Select
          defaultVal={defaultValue}
          options={CONF_OPTIONS[configKey]}
          size="default"
          style={{ marginLeft: 36 }}
        />
      </Form.Item>
    );
  }

  if (['integer', 'text'].includes(type)) {
    let component = Input;
    if (type === 'integer') component = InputNumber;

    return (
      <Form.Item name={[configKey, 'value']}>
        <ToggleInput
          label={
            <Tooltip placement="right" title={configKey}>
              {name}
            </Tooltip>
          }
          defaultVal={defaultValue}
          component={component}
          size="default"
          style={{ marginLeft: 36 }}
        />
      </Form.Item>
    );
  }

  return (
    <Typography.Paragraph type="danger">
      <ExclamationCircleOutlined /> Unknown type {type} for {configKey}
    </Typography.Paragraph>
  );
};

Field.propTypes = FieldPropTypes;

export default Field;
