import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { list as listPeopleFn } from 'common/services/people/api';

import { formConditionsOptions } from '../Form/ConditionsTab/queries';
import { VIEW_ACTIVE, VIEW_ARCHIVED, list as listPromoCodesFn } from '../PromoCodes/service';

import {
  create as createFn,
  get as getFn,
  list as listTicketTypesFn,
  updateMultiple as updateMultipleFn,
} from './service';

export const ticketsOptions = eventId => ({
  queryKey: ['events', String(eventId), 'registration', 'ticketTypes'],
  queryFn: () => listTicketTypesFn(eventId, {}, '?limit=1000'),
  placeholderData: [],
});

export const peopleCountOptions = eventId => ({
  queryKey: ['events', String(eventId), 'people', { limit: 0 }],
  queryFn: () => listPeopleFn(eventId, {}, '?limit=0', []),
  select: data => data.meta.pagination.totalItemsCount,
  placeholderData: {
    meta: {
      pagination: {
        totalItemsCount: 0,
      },
    },
  },
});

export const usePeopleCount = () => {
  const { eventId } = useParams();
  return useQuery(peopleCountOptions(eventId));
};

export const promoCodesOptions = eventId => ({
  queryKey: [
    'events',
    String(eventId),
    'registration',
    'promoCodes',
    { limit: 1000, include: 'actions' },
  ],
  queryFn: () => listPromoCodesFn(eventId, {}, { limit: 1000, include: 'actions' }),
  placeholderData: [],
});

export const usePromoCodes = queryOptions => {
  const { eventId } = useParams();
  return useQuery({ ...promoCodesOptions(eventId), ...queryOptions });
};

export const useInvalidateTickets = () => {
  const queryClient = useQueryClient();
  const { eventId } = useParams();

  return useCallback(() => {
    queryClient.invalidateQueries({ queryKey: ticketsOptions(eventId).queryKey });
    queryClient.invalidateQueries({ queryKey: peopleCountOptions(eventId).queryKey });
    queryClient.invalidateQueries({ queryKey: promoCodesOptions(eventId).queryKey });
  }, [queryClient, eventId]);
};

export const useTickets = queryOptions => {
  const { eventId } = useParams();
  return useQuery({
    ...ticketsOptions(eventId),
    ...queryOptions,
  });
};

export const useTicket = () => {
  const { eventId, ticketId } = useParams();

  return useQuery({
    queryKey: [...ticketsOptions(eventId).queryKey, ticketId],
    queryFn: () => getFn(eventId, ticketId),
    select: data => data.data,
  });
};

export const useTicketsTable = () => {
  const queryClient = useQueryClient();
  const { eventId } = useParams();
  const {
    data: ticketResult,
    isPlaceholderData: isTicketsPlaceholderData,
    isFetching: isTicketsFetching,
  } = useTickets();
  const {
    data: peopleCount,
    isPlaceholderData: isPeoplePlaceholderData,
    isFetching: isPeopleFetching,
  } = usePeopleCount();

  const {
    data: promoCodesResult,
    isPlaceholderData: isPromoCodesPlaceholderData,
    isFetching: isPromoCodesFetching,
  } = usePromoCodes();

  const isPlaceholderData =
    isTicketsPlaceholderData || isPeoplePlaceholderData || isPromoCodesPlaceholderData;

  const isFetching = isTicketsFetching || isPeopleFetching || isPromoCodesFetching;

  const invalidate = async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ticketsOptions(eventId).queryKey }),
      queryClient.invalidateQueries({ queryKey: peopleCountOptions(eventId).queryKey }),
      queryClient.invalidateQueries({ queryKey: promoCodesOptions(eventId).queryKey }),

      // Archiving a ticket might break a condition, so make sure we keep those up to date
      queryClient.invalidateQueries({ queryKey: formConditionsOptions(eventId).queryKey }),
    ]);
  };

  const next = {
    dataSources: {
      [VIEW_ACTIVE]: [],
      [VIEW_ARCHIVED]: [],
    },
    totalQuantity: 0,
    highestOrder: 0,
    peopleCount,
    isPlaceholderData,
    isFetching,
    invalidate,
  };

  const quantityCaps = [];

  ticketResult.forEach(cur => {
    const viewModeKey = cur.archived ? VIEW_ARCHIVED : VIEW_ACTIVE;

    const ticketId = cur.id;
    const attachedPromoCodes = promoCodesResult?.data?.filter(({ actions }) =>
      actions.some(({ appliedTo: { entityIds } }) => entityIds?.includes(ticketId))
    );

    next.dataSources[viewModeKey].push({ ...cur, attachedPromoCodes });

    if (!cur.archived) {
      next.highestOrder = Math.max(cur.order, next.highestOrder);

      // The displayed quantity is how many tickets are *available*, so
      // we don't include archived ones
      quantityCaps.push(cur.quantityCap);
    }
  });

  // If any tickets are unlimited, the overall capacity is too
  if (quantityCaps.includes(null)) {
    next.totalQuantity = 0;
  } else {
    next.totalQuantity = quantityCaps.reduce((acc, cur) => acc + cur, 0);
  }

  return next;
};

export const useAddTicketMutation = () => {
  const invalidate = useInvalidateTickets();
  const { eventId } = useParams();

  return useMutation(({ payload }) => createFn(eventId, payload), {
    onSuccess: () => invalidate(),
  });
};

export const useUpdateTicketsMutation = () => {
  const invalidate = useInvalidateTickets();
  const { eventId } = useParams();

  return useMutation(({ payload }) => updateMultipleFn(eventId, payload), {
    onSuccess: () => invalidate(),
  });
};

export const useArchiveTicketsMutation = () => {
  const invalidate = useInvalidateTickets();
  const { eventId } = useParams();

  return useMutation(
    ({ ticketIds }) =>
      updateMultipleFn(
        eventId,
        ticketIds.map(id => ({ id, archived: true }))
      ),
    {
      onSuccess: () => invalidate(),
    }
  );
};
