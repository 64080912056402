import apiFn from 'common/services/api-service';

const api = eventId =>
  apiFn(`/api/uapi/events/${eventId}/ticket-types`, {
    Accept: 'application/vnd.eventmobi+json; version=p.7',
  });

export function list(eventId, payload = {}, query = '') {
  const params = new URLSearchParams(query);

  if (payload.offset > 0) {
    params.set('page', payload.offset / payload.limit);
  }

  if (payload.isArchived !== undefined) {
    params.set('isArchived', payload.isArchived);
  }

  return api(eventId)
    .get('', { params })
    .then(response => response.data.data);
}

export function get(eventId, ticketId) {
  return api(eventId)
    .get(ticketId)
    .then(response => response.data);
}

export function create(eventId, payload) {
  return api(eventId)
    .post('', payload)
    .then(response => response.data.data);
}

export function update(eventId, ticketId, payload) {
  return api(eventId)
    .patch(ticketId, payload)
    .then(response => response.data?.data);
}

export function updateMultiple(eventId, payload) {
  return api(eventId)
    .patch('', payload)
    .then(response => response.data.data);
}
