import apiFn from 'common/services/api-service';

const api = eventId =>
  apiFn(`/api/uapi/events/${eventId}/registration/conditions`, {
    Accept: 'application/vnd.eventmobi+json; version=p.7',
  });

export function list(eventId, params) {
  return api(eventId)
    .get(null, { params })
    .then(response => response.data);
}

export function create(eventId, payload) {
  return api(eventId)
    .post(null, payload)
    .then(response => response.data);
}

export function updateBatch(eventId, payload) {
  return api(eventId)
    .patch(null, payload)
    .then(response => response.data);
}
